import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import 'whatwg-fetch';

// MUI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Utils
import LocalStorage from '../utils/LocalStorage';

// Constants
import { CORE, ROUTES } from '../constants';

// Components
import Header from '../components/Base/Header';
import MaintenanceModal from '../components/Modals/MaintenanceModal';

// Styling
import { withStyles } from '@material-ui/core/styles';

import { releaseNotes } from '../config';

const styles = theme => ({
  root: {},
  body: {
    background: '#eee',
    minHeight: '100vh',
    padding: `${4 * theme.spacing.unit}px 0`,
  },
  innerContent: {
    maxWidth: 1000,
    width: '100%',
    margin: '0 auto',
    padding: '16px 0',
  },
  button: {
    display: 'block',
    margin: `${2 * theme.spacing.unit}px auto`,
    maxWidth: 600,
    textAlign: 'center',
  },
  content: {
    padding: '20px 40px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  link: {
    color: 'rgba(0, 0, 0, 0.75)',
    '&:visited': {
      color: 'rgba(0, 0, 0, 0.75)',
    },
  },
  errorText: {
    color: 'rgba(155, 54, 54, 1)',
  },
});

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReleaseNotes: LocalStorage.isMonPlanUpdated(),
      releaseNotes: '',
      showMaintainanceModal: false,
    };
  }

  handleCloseMaintainanceModal = () => {
    this.setState({ showMaintainanceModal: false });
  };

  dismissReleaseNotesAndUpdateVersion = () => {
    this.setState({
      showReleaseNotes: false,
    });
    LocalStorage.setMonPlanVersion(process.env.REACT_APP_VERSION);
  };

  renderDefaultContent = () => {
    const { user, isAuth } = this.props;
    return (
      <React.Fragment>
        {/* GENERAL */}
        <Typography variant="display1" align="center" gutterBottom>
          {isAuth && user.firstName ? `Hello ${user.firstName}! ` : 'Hello! '}
          Welcome to MonPlan!
        </Typography>
        <h3 style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.54)' }}>
          {CORE.GENERAL.SLOGAN}
        </h3>
        <h3 style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Before you proceed...</h3>
        <Typography
          variant="subheading"
          align="left"
          gutterBottom
          style={{
            maxWidth: 1000,
            margin: '2em auto 1em auto',
          }}>
          MonPlan is your interactive course map to help you plan your course
          and select the right units.
        </Typography>
        <Typography
          variant="subheading"
          align="left"
          gutterBottom
          style={{
            maxWidth: 1000,
            margin: '1em auto',
          }}>
          Features in MonPlan allow you to:
          <ul>
            <li>manage multiple course plans </li>
            <li>prepopulate units from your current course</li>
            <li>
              identify your eligibility to enrol in units with pre and
              corequisites
            </li>
            <li>
              You can share your course map with a course adviser for advice.
            </li>
            <li>and more.</li>
          </ul>
          <b>
            MonPlan does not enrol you in units, so make sure you enter your
            unit selection in the{' '}
            <a
              href={CORE.EXTERNAL_LINKS.MONASH.WES}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              style={{ color: 'rgba(0,0,0,0.87)' }}>
              Web Enrolment System (WES)
            </a>{' '}
            to enrol in your course.
          </b>
        </Typography>
        <Typography
          variant="subheading"
          align="left"
          gutterBottom
          style={{
            maxWidth: 1000,
            margin: '1em auto',
          }}>
          If you are unsure about your plan, consult the{' '}
          <a
            href={CORE.EXTERNAL_LINKS.MONASH.HANDBOOK}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            style={{ color: 'rgba(0,0,0,0.87)' }}>
            University Handbook
          </a>
          . If you remain unsure, a{' '}
          <a
            href={CORE.EXTERNAL_LINKS.MONASH.COURSE_ADVICE}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            style={{ color: 'rgba(0,0,0,0.87)' }}>
            course adviser
          </a>{' '}
          can review your course plan.
        </Typography>
        <Typography
          variant="subheading"
          align="left"
          gutterBottom
          style={{
            maxWidth: 1000,
            margin: '1em auto 2em auto',
          }}>
          While we've made every effort to ensure the course and unit
          information in MonPlan is correct, we can’t guarantee accuracy,
          completeness or reliability. You should consider the appropriateness
          of the information in MonPlan to your individual educational
          objectives before making any enrolment variations via WES.
        </Typography>
      </React.Fragment>
    );
  };

  renderMSAContent = () => {
    return (
      <React.Fragment>
        <Typography variant="display1" align="center" gutterBottom>
          Welcome to MonPlan!
        </Typography>
        <h3 style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Before you proceed...</h3>
        <Typography
          variant="subheading"
          align="left"
          gutterBottom
          style={{
            maxWidth: 1000,
            margin: '2em auto 1em auto',
          }}>
          MonPlan is your electronic course plan outlining the sequence of units
          you need to enrol in to ensure timely completion of your course.
        </Typography>
        <Typography
          variant="subheading"
          align="left"
          gutterBottom
          style={{
            maxWidth: 1000,
            margin: '1em auto',
          }}>
          There are strict time limits for your course. Please visit{' '}
          <a
            href={CORE.EXTERNAL_LINKS.MSA.COURSE_EXPIRY}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            style={{ color: 'rgba(0,0,0,0.87)' }}>
              {CORE.EXTERNAL_LINKS.MSA.COURSE_EXPIRY}
          </a>{' '}
          for detailed information about the time limit for completion of your
          course.
        </Typography>
        <Typography
          variant="subheading"
          align="left"
          gutterBottom
          style={{
            maxWidth: 1000,
            margin: '1em auto',
          }}>
          <strong>
            MonPlan does not enrol you in units, so make sure you enter your
            unit selection in the{' '}
            <a
              href={CORE.EXTERNAL_LINKS.MONASH.WES}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              style={{ color: 'rgba(0,0,0,0.87)' }}>
              Web Enrolment System (WES)
            </a>{' '}
            to enrol in your course.
          </strong>
        </Typography>
        <Typography
          variant="subheading"
          align="left"
          gutterBottom
          style={{
            maxWidth: 1000,
            margin: '2em auto 1em auto',
          }}>
          While we’ve made every effort to ensure the course and unit
          information in MonPlan is correct, we can’t guarantee accuracy,
          completeness or reliability. If any unit offerings change, we will
          update your plan and you will be notified via your student email
          account to view changes in MonPlan.
        </Typography>
        <Typography
          variant="subheading"
          align="left"
          gutterBottom
          style={{
            maxWidth: 1000,
            margin: '2em auto 1em auto',
          }}>
          If you are unsure about your plan, or have difficulties enrolling,
          please contact the{' '}
          <a
            href={CORE.EXTERNAL_LINKS.MSA.CONTACT_US}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            style={{ color: 'rgba(0,0,0,0.87)' }}>
            Student Services Centre
          </a>
          .
        </Typography>
        <Typography
          variant="subheading"
          align="left"
          gutterBottom
          style={{
            maxWidth: 1000,
            margin: '2em auto 1em auto',
          }}>
          Please sign in below to access your course plan.
        </Typography>
      </React.Fragment>
    );
  };

  renderReleaseNotesDialog = () => {
    const { classes } = this.props;
    const { showReleaseNotes } = this.state;
    return (
      <Dialog
        open={showReleaseNotes}
        onClose={this.dismissReleaseNotesAndUpdateVersion}
        style={{ overflowY: 'scroll' }}>
        <DialogTitle>
          {`Welcome to MonPlan v${process.env.REACT_APP_VERSION}!`}
        </DialogTitle>
        <DialogContent>
          <a href="https://monplan.apps.monash.edu" className={classes.link}>
            MonPlan
          </a>{' '}
          is the enterprise course planning tool for{' '}
          <a href="https://monash.edu" className={classes.link}>
            Monash University
          </a>
          . For any technical issues please contact the{' '}
          <a
            href="https://servicedeskonline.monash.edu"
            className={classes.link}>
            ServiceDesk
          </a>
        </DialogContent>
        <DialogContent>
          {releaseNotes ? (
            <div>
              {releaseNotes.features.length > 0 && (
                <div>
                  <h2>Features</h2>
                  <ul>
                    {releaseNotes.features.map((feature, key) => {
                      return <li key={key}>{feature}</li>;
                    })}
                  </ul>
                </div>
              )}
              {releaseNotes.fixes.length > 0 && (
                <div>
                  <h2>Fixes</h2>
                  <ul>
                    {releaseNotes.fixes.map((feature, key) => {
                      return <li key={key}>{feature}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <DialogContentText className={classes.errorText}>
              Unable to fetch data
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.dismissReleaseNotesAndUpdateVersion}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes, user, isAuth } = this.props;

    const isSouthAfricaUser = isAuth && user.isMsa;
    const content = isSouthAfricaUser
      ? this.renderMSAContent()
      : this.renderDefaultContent();

    return (
      <React.Fragment>
        {!isSouthAfricaUser && this.renderReleaseNotesDialog()}
        <Header />

        <div className={classes.body}>
          <div className={classes.innerContent}>
            <Paper className={classes.content} tabIndex="0">
              {content}
              {/* COURSE ADVISOR */}
              {isAuth &&
                user.isStaff && (
                  <React.Fragment>
                    <Button
                      fullWidth
                      variant="raised"
                      color="primary"
                      style={{ color: 'white' }}
                      component={Link}
                      to={ROUTES.STUDENT_SEARCH}
                      onClick={() => browserHistory.push(ROUTES.STUDENT_SEARCH)}
                      classes={{ root: classes.button }}>
                      Find a student by ID
                    </Button>
                  </React.Fragment>
                )}
              {isAuth &&
                (user.isAdmin && (
                  <div>
                    <React.Fragment>
                      <Button
                        fullWidth
                        variant="raised"
                        color="primary"
                        style={{ color: 'white' }}
                        component={Link}
                        to={ROUTES.ADMIN_DASHBOARD}
                        onClick={() =>
                          browserHistory.push(ROUTES.ADMIN_DASHBOARD)
                        }
                        classes={{ root: classes.button }}>
                        Configure MonPlan
                      </Button>
                      {window.location.origin !== 'monplan.apps.monash.edu' && (
                        <Button
                          fullWidth
                          variant="raised"
                          color="primary"
                          component={'a'}
                          style={{ color: 'white' }}
                          href={`${window.location.origin}${ROUTES.MOCK_LOGIN}`}
                          onClick={() =>
                            (window.location = `${window.location.origin}${
                              ROUTES.MOCK_LOGIN
                            }`)
                          }
                          classes={{ root: classes.button }}>
                          Mock Login (debug)
                        </Button>
                      )}
                    </React.Fragment>
                  </div>
                ))}

              {/* STUDENT */}
              {isAuth &&
                user.isStudent && (
                  <React.Fragment>
                    <Button
                      fullWidth
                      variant="raised"
                      color="primary"
                      component={Link}
                      to={ROUTES.LIST_PLANS}
                      onClick={() => browserHistory.push(ROUTES.LIST_PLANS)}
                      style={{ color: 'white' }}
                      classes={{ root: classes.button }}>
                      View My Course Plans
                    </Button>
                  </React.Fragment>
                )}

              {/* ANONYMOUS */}
              {!isAuth && (
                <React.Fragment>
                  <Typography variant="subheading" align="center" gutterBottom>
                    MonPlan is currently only open to current Monash University
                    students, so make sure you sign in below!
                    <br />
                    You will be able to manage multiple course plans, engage
                    with your course advisers and load units from your currently
                    enrolled course!
                  </Typography>
                  <Button
                    fullWidth
                    variant="raised"
                    color="primary"
                    component={'a'}
                    style={{ color: 'white' }}
                    href={`${window.location.origin}${ROUTES.LOGIN}`}
                    onClick={() =>
                      (window.location = `${window.location.origin}${
                        ROUTES.LOGIN
                      }`)
                    }
                    classes={{ root: classes.button }}>
                    Log in with your Monash Account!
                  </Button>
                </React.Fragment>
              )}
            </Paper>
          </div>
        </div>
        <div />
        <MaintenanceModal
          open={this.state.showMaintainanceModal}
          onClose={this.handleCloseMaintainanceModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.User.user,
  isAuth: state.User.isAuth,
});

export default connect(mapStateToProps)(withStyles(styles)(Landing));
